<template>
    <div>
      <b-card class="mb-0">
        <template #header>
          <b-row class=" d-flex justify-content-start" style="align-items: center;flex:1">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              class="d-flex"
              style="align-items:center;"
              @click="back()"
            >
              <feather-icon icon="ArrowLeftCircleIcon" class="mr-50" size="23" />
              <span class="align-middle">{{$t('general_buttons.back')}}</span>
            </b-button>
            <h3 class="mb-0 ml-2">{{$t('fx_options.titles.selected_same_options')}}</h3>
           
            <b-button
                    id="info-legend-button"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="flat-primary"
                    size="sm"
                    class="compact_nav_icon-text-button mr-1"
                    style="margin-left:auto"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      size="14"
                      style="margin-bottom: 0.3rem;"
                    />
  
                    <span class="text-nowrap">{{
                      $t("fx_options.buttons.legend_table")
                    }}</span>
            </b-button>
            <b-popover
            target="info-legend-button"
            placement="left"
            triggers="click blur" 
            >
            <template #title>
                {{ $t("fx_options.legend_table.legend") }}
            </template>
            <b-table-simple hover small caption-top responsive bordered>
                <b-tbody>
                <b-tr>
                    <td style="width: 60%;white-space: nowrap;">
                    {{ $t("fx_options.legend_table.current_trade") }}
                    </td>
                    <td style="background-color: #fcfcab !important;"></td>
                </b-tr>
                </b-tbody>
            </b-table-simple>
            </b-popover>
        </b-row>
        </template>
        <b-overlay :show="loading" rounded="sm" no-fade>
          <b-table
            v-if="sameOptionsDatas.length > 0"
            hover
            ref="refFXoptionsTable"
            class="compact_table"
            thead-class="animate__animated animate__fadeIn"
            :items="sameOptionsDatas"
            :filter="filter"
            filter-debounce="250"
            @filtered="onFiltered"
            :filter-included-fields="filterOn"
            selectable
            select-mode="single"
            responsive
            :small="true"
            head-variant="dark"
            :fields="tableColumns"
            show-empty
            :empty-text="$t('fx_options.messages.no_matching_trade_records')"
            :current-page="currentPage"
            :per-page="perPage"
            :tbody-tr-class="legendClass"
            @row-clicked="viewSelectedTrade"
            caption-top
          >
            <!-- Column: show_details -->
            <!-- <template #cell(show_details)="row">
                <b-form-checkbox
                  size="md"
                  v-model="row.detailsShowing"
                  @change="row.toggleDetails"
                  name="check-button"
                  button
                  v-b-tooltip.hover
                  :title="
                    row.detailsShowing
                      ? 'Hide Trade Controls'
                      : 'Show Trade Controls'
                  "
                  button-variant="flat-primary"
                >
                  <feather-icon
                    :icon="row.detailsShowing ? 'EyeOffIcon' : 'EyeIcon'"
                  />
                </b-form-checkbox>
              </template> -->
  
              <!-- ID -->
              <template #cell(ID)="data">
                <b
                  ><span class="font-weight-bold text-center">
                    <strong>{{ data.item.ID ? data.item.ID : "-" }}</strong>
                  </span></b
                >
              </template>
    
                <!-- Column: counterparty -->
                <template #cell(Cpty)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{ data.item.Cpty ? data.item.Cpty : "-" }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: Client -->
                <template #cell(Client)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.Client ? data.item.Client : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: TradeDate -->
                <template #cell(TradeDate)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.TradeDate
                          ? dateFormat(data.item.TradeDate)
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: Expiry date -->
                <template #cell(Expiry)="data">
                  <b
                    ><span class="font-weight-bold" style="white-space: nowrap;">
                      <strong>{{
                        data.item.Expiry ? dateFormat(data.item.Expiry) : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: ValueDate -->
                <template #cell(ValueDate)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.ValueDate
                          ? dateFormat(data.item.ValueDate)
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: SettlDate -->
                <template #cell(SettlDate)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.SettlDate
                          ? dateFormat(data.item.SettlDate)
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: TcUser -->
                <template #cell(TcUser)="data">
                  <b
                    ><span class="font-weight-bold" style="white-space: nowrap;">
                      <strong>{{
                        data.item.TcUser ? data.item.TcUser : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: BuySell -->
                <template #cell(BuySell)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.BuySell ? data.item.BuySell : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: Strike -->
                <template #cell(Strike)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.Strike ? formatPrice(data.item.Strike, 4) : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: Notional -->
                <template #cell(Notional)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.Notional
                          ? formatPrice(data.item.Notional, 2)
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: NotionalCcy -->
                <template #cell(NotionalCcy)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.NotionalCcy ? data.item.NotionalCcy : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: ClientPremium -->
                <template #cell(ClientPremium)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.ClientPremium != null
                          ? formatPrice(data.item.ClientPremium, 2)
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: ClientPremiumCurrency && PremCcy -->
                <template #cell(PremCcy)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.PremCcy != null
                          ? data.item.PremCcy
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
               
  
                <!-- Column: OptionType -->
                <template #cell(OptionType)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.OptionType ? data.item.OptionType : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: OptionExercise -->
                <template #cell(OptionExercise)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.OptionExercise ? data.item.OptionExercise : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: SettlementType -->
                <template #cell(SettlementType)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.SettlementType ? data.item.SettlementType : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: CutOffTime -->
                <template #cell(CutOffTime)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.CutOffTime ? data.item.CutOffTime : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: ClientOpen -->
                <template #cell(ClientOpen)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.ClientOpen
                          ? formatPrice(data.item.ClientOpen, 2)
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: CptyPrem -->
                <template #cell(CptyPrem)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.CptyPrem
                          ? formatPrice(data.item.CptyPrem, 2)
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: TcComm -->
                <template #cell(TcComm)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.TcComm ? formatPrice(data.item.TcComm, 2) : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: CptyPctPrem -->
                <template #cell(CptyPctPrem)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.CptyPctPrem
                          ? data.item.CptyPctPrem + '%'
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: ClientPctPrem -->
                <template #cell(ClientPctPrem)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.ClientPctPrem
                          ? data.item.ClientPctPrem + '%'
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: OrderTime -->
                <template #cell(OrderTime)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.OrderTime ? data.item.OrderTime : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: ExecutionTime -->
                <template #cell(ExecutionTime)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.ExecutionTime ? data.item.ExecutionTime : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: CounterpartyAccount -->
                <template #cell(CptyAcc)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.CptyAcc ? data.item.CptyAcc : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: ClientAccount -->
                <template #cell(ClientAcc)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.ClientAcc ? data.item.ClientAcc : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: CounterpartyTrader -->
                <template #cell(CptyTrader)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.CptyTrader ? data.item.CptyTrader : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: OrderTakenVia  -->
                <template #cell(OrderTakenVia)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.OrderTakenVia ? data.item.OrderTakenVia : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: OrderGivenThrough  -->
                <template #cell(OrderGivenThrough)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.OrderGivenThrough
                          ? data.item.OrderGivenThrough
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: ActingCapacity  -->
                <template #cell(ActingCapacity)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.ActingCapacity ? data.item.ActingCapacity : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: SpotRef  -->
                <template #cell(SpotRef)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.SpotRef
                          ? formatPrice(data.item.SpotRef, 4)
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: TraderNote  -->
                <template #cell(TraderNote)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.TraderNote ? data.item.TraderNote : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: Prop  -->
                <template #cell(Prop)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{ data.item.Prop ? "Yes" : "No" }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: CollateralCcy  -->
                <template #cell(CollateralCcy)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.CollateralCcy ? data.item.CollateralCcy : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: Collateral  -->
                <template #cell(Collateral)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.Collateral
                          ? formatPrice(data.item.Collateral, 2)
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: CollateralPct  -->
                <template #cell(CollateralPct)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.CollateralPct ? data.item.CollateralPct : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: WindowStart  -->
                <template #cell(WindowStart)="data">
                  <b
                    ><span class="font-weight-bold" style="white-space: nowrap;">
                      <strong>{{
                        data.item.WindowStart
                          ? dateFormat(data.item.WindowStart)
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: WindowEnd  -->
                <template #cell(WindowEnd)="data">
                  <b
                    ><span class="font-weight-bold" style="white-space: nowrap;">
                      <strong>{{
                        data.item.WindowEnd
                          ? dateFormat(data.item.WindowEnd)
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: ConfirmationDate  -->
                <template #cell(ConfirmationDate)="data">
                  <b
                    ><span class="font-weight-bold" style="white-space: nowrap;">
                      <strong>{{
                        data.item.ConfirmationDate
                          ? dateFormat(data.item.ConfirmationDate)
                          : "-"
                      }}</strong>
                    </span></b
                  > </template
                >,
  
                <!-- Column: ConfirmationTime  -->
                <template #cell(ConfirmationTime)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.ConfirmationTime
                          ? data.item.ConfirmationTime
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: ConfirmationTrader  -->
                <template #cell(ConfirmationTrader)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.ConfirmationTrader
                          ? data.item.ConfirmationTrader
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: Counterparty UTI  -->
                <template #cell(CptyUti)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.CptyUti ? data.item.CptyUti : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: UniqueLinkId  -->
                <template #cell(UniqueLinkId)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.UniqueLinkId ? data.item.UniqueLinkId : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: ClearingMemberId  -->
                <template #cell(ClearingMemberId)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.ClearingMemberId
                          ? data.item.ClearingMemberId
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: T&C Confo Date  -->
                <template #cell(ConfirmationDateClient)="data">
                  <b
                    ><span class="font-weight-bold" style="white-space: nowrap;">
                      <strong>{{
                        data.item.ConfirmationDateClient
                          ? dateFormat(data.item.ConfirmationDateClient)
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: T&C Confo Time  -->
                <template #cell(ConfirmationTimeClient)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.ConfirmationTimeClient
                          ? data.item.ConfirmationTimeClient
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: Counterparty Ref  -->
                <template #cell(CpRef)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.CpRef ? data.item.CpRef : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: EntryType  -->
                <template #cell(EntryType)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.EntryType ? data.item.EntryType : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: SavedBy -->
                <template #cell(SavedBy)="data">
                  <b
                    ><span class="font-weight-bold" style="white-space: nowrap;">
                      <strong>{{
                        data.item.SavedBy ? data.item.SavedBy : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: SaveDate -->
                <template #cell(SaveDate)="data">
                  <b
                    ><span class="font-weight-bold" style="white-space: nowrap;">
                      <strong>{{
                        data.item.SaveDate != null
                          ? dateFormat(data.item.SaveDate)
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: SaveTime -->
                <template #cell(SaveTime)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.SaveTime != null ? data.item.SaveTime : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: AmendedBy -->
                <template #cell(AmendedBy)="data">
                  <b
                    ><span class="font-weight-bold" style="white-space: nowrap;">
                      <strong>{{
                        data.item.AmendedBy ? data.item.AmendedBy : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: AmendmentDate -->
                <template #cell(AmendmentDate)="data">
                  <b
                    ><span class="font-weight-bold" style="white-space: nowrap;">
                      <strong>{{
                        data.item.AmendmentDate != null
                          ? dateFormat(data.item.AmendmentDate)
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: AmendmentTime -->
                <template #cell(AmendmentTime)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.AmendmentTime != null
                          ? data.item.AmendmentTime
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: AmendmentReason -->
                <template #cell(AmendmentReason)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.AmendmentReason
                          ? data.item.AmendmentReason
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: Cancelled -->
                <template #cell(Cancelled)="data">
                  <b
                    ><span class="font-weight-bold text-white">
                      <strong>{{
                        data.item.Cancelled == 1 ? "Cancelled" : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: CancelledBy -->
                <template #cell(CancelledBy)="data">
                  <b
                    ><span class="font-weight-bold" style="white-space: nowrap;">
                      <strong>{{
                        data.item.CancelledBy ? data.item.CancelledBy : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: CancelDate -->
                <template #cell(CancelDate)="data">
                  <b
                    ><span class="font-weight-bold" style="white-space: nowrap;">
                      <strong>{{
                        data.item.CancelDate != null
                          ? dateFormat(data.item.CancelDate)
                          : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: CancelTime -->
                <template #cell(CancelTime)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.CancelTime != null ? data.item.CancelTime : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
  
                <!-- Column: CancelReason -->
                <template #cell(CancelReason)="data">
                  <b
                    ><span class="font-weight-bold">
                      <strong>{{
                        data.item.CancelReason ? data.item.CancelReason : "-"
                      }}</strong>
                    </span></b
                  >
                </template>
          </b-table>
  
          <div class="mx-2 mb-2" v-if="sameOptionsDatas.length > 0">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted" style="font-size:0.8rem"
                  >{{$t('general_text.showing')}} {{ dataMeta.from }} {{$t('general_text.to')}} {{ dataMeta.to }} {{$t('general_text.of')}}
                  {{ dataMeta.of }} {{$t('general_text.entries')}}</span
                >
              </b-col>
  
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0 compact_paginate"
                  prev-class="prev-item"
                  next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
  
          <div v-if="sameOptionsDatas.length == 0">
            <b-alert variant="info" show>
              <div class="alert-body text-center">
                <span
                  ><strong>{{$t('fx_options.titles.not_found_trades')}}</strong> <br />
                  {{$t('fx_options.messages.not_match_same_option')}}</span
                >
              </div>
            </b-alert>
          </div>
        </b-overlay>
  
        {{ /*View Selected Trade for trades modal*/ }}
        <ViewSelectedTrade ref="ViewSelectedTrade" />
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    VBModal,
    BTabs,
    BTab,
    BCardText,
    BIcon,
    BPopover,
  } from "bootstrap-vue";
  import store from "@/store";
  import { onUnmounted } from "@vue/composition-api";
  import fXOptionsStoreModule from "../FXOptionsStoreModule";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required, alphaNum, email } from "@validations";
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  import Ripple from "vue-ripple-directive";
  import vSelect from "vue-select";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import axios from "axios";
  import axiosIns from "@/libs/axios";
  import router from "@/router";
  import moment from "moment";
  import "animate.css";
  import _ from "lodash";
  import { mapState, mapGetters } from "vuex";
  import ViewSelectedTrade from "./ViewSelectedDetail.vue";
  
  export default {
    components: {
      BOverlay,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BTabs,
      BTab,
      BCardText,
      BDropdown,
      BDropdownItem,
      BPagination,
      BFormRating,
      BIcon,
      BPopover,
      vSelect,
      ValidationProvider,
      ValidationObserver,
      ToastificationContent,
      flatPickr,
      ViewSelectedTrade,
    },
  
    directives: {
      Ripple,
      "b-modal": VBModal,
    },
  
    setup() {
        const FXOPTION_APP_STORE_MODULE_NAME = "fx_options";
        // Register module
        if (!store.hasModule(FXOPTION_APP_STORE_MODULE_NAME))
          store.registerModule(
            FXOPTION_APP_STORE_MODULE_NAME,
            fXOptionsStoreModule
          );
    
        // UnRegister on leave
        onUnmounted(() => {
          if (store.hasModule(FXOPTION_APP_STORE_MODULE_NAME))
            store.unregisterModule(FXOPTION_APP_STORE_MODULE_NAME);
        });
      },
  
    mounted() {
      this.detailId = this.$route.params.id;
      this.getSameOptionsSelectedClient(this.$route.params.id);
    },
    created() {
      window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    data() {
      return {
        sameOptionsDatas: [],
        detailId: null,
        filterOn: [
          "TcUser",
          "Cpty",
          "Client",
          "OrderGivenThrough",
          "OrderTakenVia",
        ],
        tableColumns: [
        // { key: "show_details", label: "" },
  
        {
          stickyColumn: true,
          key: "ID",
          label: "ID",
          // thClass: "text-left",
          tdClass: "text-center ID_background",
          thClass: "sticky-column",
          thStyle: "width: 50px",
          sortable: true,
          class: "text-center table-padding sticky-column",
          variant: "primary",
        },
        {
          key: "TcUti",
          label: this.$t("fx_options.table.tcuti"),
          class: "text-start table-padding",
          sortable: true,
        },
        {
          key: "TcUser",
          label: this.$t("fx_options.table.operations_user"),
          class: "text-start table-padding",
          sortable: true,
        },
        {
          key: "Cpty",
          label: this.$t("fx_options.table.counterparty"),
          class: "text-start table-padding",
        },
        {
          key: "CptyAcc",
          label: this.$t("fx_options.table.CounterpartyAccount"),
          class: "text-start table-padding",
        },
        {
          key: "Client",
          label: this.$t("fx_options.table.client"),
          class: "text-start table-padding",
        },
  
        {
          key: "ClientAcc",
          label: this.$t("fx_options.table.ClientAccount"),
          class: "text-start table-padding",
        },
        {
          key: "CptyTrader",
          label: this.$t("fx_options.table.CounterpartyTrader"),
          class: "text-start table-padding",
        },
        {
          key: "TradeDate",
          label: this.$t("fx_options.table.trade_date"),
          class: "text-start table-padding text-nowrap",
        },
  
        {
          key: "Expiry",
          label: this.$t("fx_options.table.expiry"),
          class: "text-start table-padding",
        },
  
        {
          key: "ValueDate",
          label: this.$t("fx_options.table.value_date"),
          class: "text-start table-padding text-nowrap",
        },
  
        {
          key: "SettlDate",
          label: this.$t("fx_options.table.settle_date"),
          class: "text-start table-padding text-nowrap",
        },
  
        {
          key: "BuySell",
          label: this.$t("fx_options.table.buy_sell"),
          class: "text-start table-padding",
        },
        {
          key: "Strike",
          label: this.$t("fx_options.table.strike"),
          class: "text-start table-padding",
        },
  
        {
          key: "Notional",
          label: this.$t("fx_options.table.notional"),
          class: "text-start table-padding",
        },
  
        {
          key: "NotionalCcy",
          label: this.$t("fx_options.table.notional_currency"),
          class: "text-start table-padding",
          sortable: true,
        },
        {
          key: "ClientPremium",
          label: this.$t("fx_options.table.client_premium"),
          class: "text-start table-padding",
        },
  
        {
          key: "PremCcy",
          label: this.$t("fx_options.table.client_premium_currency"),
          class: "text-start table-padding",
          sortable: true,
        },
  
        {
          key: "OptionType",
          label: this.$t("fx_options.table.option_type"),
          class: "text-start table-padding",
        },
        {
          key: "OptionExercise",
          label: this.$t("fx_options.table.option_exercise"),
          class: "text-start table-padding",
        },
        {
          key: "SettlementType",
          label: this.$t("fx_options.table.settlement_type"),
          class: "text-start table-padding",
        },
        {
          key: "CutOffTime",
          label: this.$t("fx_options.table.cutofftime"),
          class: "text-start table-padding",
        },
        {
          key: "ClientOpen",
          label: this.$t("fx_options.table.client_open"),
          class: "text-start table-padding",
        },
  
        {
          key: "CptyPrem",
          label: this.$t("fx_options.table.cpty_open"),
          class: "text-start table-padding",
        },
  
        {
          key: "TcComm",
          label: this.$t("fx_options.table.tc_comission"),
          class: "text-start table-padding",
          sortable: true,
        },
  
        {
          key: "CptyPctPrem",
          label: this.$t("fx_options.table.CounterpartyPctPremium"),
          class: "text-start table-padding",
        },
  
        {
          key: "ClientPctPrem",
          label: this.$t("fx_options.table.ClientPctPremium"),
          class: "text-start table-padding",
          sortable: true,
        },
        {
          key: "OrderTime",
          label: this.$t("fx_options.table.OrderTime"),
          class: "text-start table-padding",
        },
        {
          key: "ExecutionTime",
          label: this.$t("fx_options.table.ExecutionTime"),
          class: "text-start table-padding",
        },
  
        {
          key: "OrderTakenVia",
          label: this.$t("fx_options.table.OrderTakenVia"),
          class: "text-start table-padding",
        },
        {
          key: "OrderGivenThrough",
          label: this.$t("fx_options.table.OrderGivenThrough"),
          class: "text-start table-padding",
        },
        {
          key: "ActingCapacity",
          label: this.$t("fx_options.table.ActingCapacity"),
          class: "text-start table-padding",
        },
        {
          key: "SpotRef",
          label: this.$t("fx_options.table.SpotRef"),
          class: "text-start table-padding",
        },
        {
          key: "TraderNote",
          label: this.$t("fx_options.table.TraderNote"),
          class: "text-start table-padding",
        },
        {
          key: "Prop",
          label: this.$t("fx_options.table.Prop"),
          class: "text-start table-padding",
        },
        {
          key: "CollateralCcy",
          label: this.$t("fx_options.table.CollateralCcy"),
          class: "text-start table-padding",
        },
        {
          key: "Collateral",
          label: this.$t("fx_options.table.Collateral"),
          class: "text-start table-padding",
        },
        {
          key: "CollateralPct",
          label: this.$t("fx_options.table.CollateralPct"),
          class: "text-start table-padding",
        },
        {
          key: "WindowStart",
          label: this.$t("fx_options.table.WindowStart"),
          class: "text-start table-padding",
        },
        {
          key: "WindowEnd",
          label: this.$t("fx_options.table.WindowEnd"),
          class: "text-start table-padding",
        },
        {
          key: "ConfirmationDate",
          label: this.$t("fx_options.table.CptyConfoDate"),
          class: "text-start table-padding",
        },
        {
          key: "ConfirmationTime",
          label: this.$t("fx_options.table.CptyConfoTime"),
          class: "text-start table-padding",
        },
        {
          key: "ConfirmationTrader",
          label: this.$t("fx_options.table.ConfirmationTrader"),
          class: "text-start table-padding",
        },
        {
          key: "CptyUti",
          label: this.$t("fx_options.table.CptyUti"),
          class: "text-start table-padding",
        },
        {
          key: "UniqueLinkId",
          label: this.$t("fx_options.table.UniqueLinkId"),
          class: "text-start table-padding",
        },
        {
          key: "ClearingMemberId",
          label: this.$t("fx_options.table.ClearingMemberId"),
          class: "text-start table-padding",
        },
        {
          key: "ConfirmationDateClient",
          label: this.$t("fx_options.table.ConfirmationDateClient"),
          class: "text-start table-padding",
        },
        {
          key: "ConfirmationTimeClient",
          label: this.$t("fx_options.table.ConfirmationTimeClient"),
          class: "text-start table-padding",
        },
        {
          key: "CpRef",
          label: this.$t("fx_options.table.CpRef"),
          class: "text-start table-padding",
        },
        {
          key: "EntryType",
          label: this.$t("fx_options.table.EntryType"),
          class: "text-start table-padding",
        },
        {
          key: "SavedBy",
          label: this.$t("fx_options.table.saved_by"),
          class: "text-start table-padding",
        },
        {
          key: "SaveDate",
          label: this.$t("fx_options.table.save_date"),
          class: "text-start text-nowrap table-padding",
          sortable: true,
        },
        {
          key: "SaveTime",
          label: this.$t("fx_options.table.save_time"),
          class: "text-start table-padding",
        },
        {
          key: "AmendedBy",
          label: this.$t("fx_options.table.amended_by"),
          class: "text-start table-padding",
        },
        {
          key: "AmendmentDate",
          label: this.$t("fx_options.table.amendment_date"),
          class: "text-start table-padding text-nowrap",
          sortable: true,
        },
        {
          key: "AmendmentTime",
          label: this.$t("fx_options.table.amendment_time"),
          class: "text-start table-padding",
        },
        {
          key: "AmendmentReason",
          label: this.$t("fx_options.table.amendment_reason"),
          class: "text-start table-padding",
        },
  
        {
          key: "Cancelled",
          label: this.$t("fx_options.table.cancelled"),
          class: "text-start table-padding",
        },
        {
          key: "CancelledBy",
          label: this.$t("fx_options.table.cancelled_by"),
          class: "text-start table-padding",
        },
        {
          key: "CancelDate",
          label: this.$t("fx_options.table.canceled_date"),
          class: "text-start table-padding text-nowrap",
          sortable: true,
        },
        {
          key: "CancelTime",
          label: this.$t("fx_options.table.cancelled_time"),
          class: "text-start table-padding",
        },
        {
          key: "CancelReason",
          label: this.$t("fx_options.table.cancelled_reason"),
          class: "text-start table-padding",
        },
        // {
        //   stickyColumn: true,
        //   key: "TradeActions",
        //   label: "Actions",
        //   class: "text-center",
        //   isRowHeader: true,
        //   thStyle:"background-color:#ebe9f1;color:black;border: 1px solid #ebe9f1;"
        // },
      ],
  
        perPage: 25,
        currentPage: 1,
        totalRows: 0,
        perPageOptions: [10, 25, 50, 100],
        filter: null,
        loading: false,
        filterSettlementDate: null,
        filterTradeDate: null,
        canceledReasonText: null,
      };
    },
    computed: {
      user: {
        get() {
          return this.$store.getters["authUser"];
        },
        set(newValue) {
          return this.$store.dispatch("setUser", newValue);
        },
      },
      dataMeta() {
        return {
          from:
            this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
          to:
            this.perPage * (this.currentPage - 1) + Number(this.perPage) <
            this.totalRows
              ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
              : this.totalRows,
          of: this.totalRows,
        };
      },
    },
    methods: {
      back() {
        router.go(-1);
      },
      legendClass(item, type) {
        // const executedRowColor = "table-executed";
        const verifiedRowColor = "table-verified";
        // const journalCreateRowColor = "table-journalCreate";
        // const expiredOrderRowColor = "table-expiredOrder";
        // const reversedInitiateRowColor = "table-reverseInitiated";
        // const reversedRowColor = "table-reversed";
        const canceledRowColor = "table-canceled";
        const currentTradeRowColor = "table-current";
  
        if (!item || type !== "row") {
          return;
        }
  
        if (item.Cancelled == 1) {
          return canceledRowColor;
        }
        // if (item.Reversed == 1) {
        //   return reversedRowColor;
        // }
        // if (item.ReversalInitiated == 1) {
        //   return reversedInitiateRowColor;
        // }
        // if (item.ExpiredOrder == 1) {
        //   return expiredOrderRowColor;
        // }
        // if (item.JournalCreated == 1) {
        //   return journalCreateRowColor;
        // }
        if (item.Verified == 1) {
          return verifiedRowColor;
        }
        // if (item.EntryType == "Execution") {
        //   return executedRowColor;
        // }

        if (item.ID == this.detailId) {
          return currentTradeRowColor;
        }
      },
      getSameOptionsSelectedClient(id) {
        this.$checkAccessRight("FXOptions", "View").then((res) => {
          if (res.data.valid) {
            this.loading = true;
            store
              .dispatch("fx_options/getSameOptionsSelectedClient", {
                auth: this.user,
                tradeId: id,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  
                  if (res.data.options.length > 0) {
                    res.data.options.forEach((data) => {
                      this.sameOptionsDatas.push(data);
                    });
                  }
                  this.totalRows = this.sameOptionsDatas.length;
                  this.loading = false;
                } else {
                    if(res.data.info == "not_found_match"){
                        this.warningToastMessage(this.$t("fx_options.messages.not_match_same_option"));
                    } else {
                        this.notFoundMessage();
                    }
                  this.loading = false;
                  router.go(-1);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.loading = false;
          }
        });
      },
      viewSelectedTrade(item) {
        console.log(item)
        this.$checkAccessRight("FXOptions", "View").then((res) => {
          if (res.data.valid) {
            if (item.ID != 0) {
              store
                .dispatch("fx_options/getViewSelectedFxOptionTrade", {
                  auth: this.user,
                  tradeId: item.ID,
                  tcUti: item.TcUti,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    this.$refs.ViewSelectedTrade.viewSelectedTradeModal = true;
                    this.$refs.ViewSelectedTrade.fetchedTradeData =
                      res.data.trade;
  
                      // set changes red color inputs
                      if (res.data.changedInputs) {
                          let setChangedInputs = _.map(
                            res.data.changedInputs,
                            (value, key) => ({ key, value })
                          );
                          if (setChangedInputs.length > 0) {
                            this.$refs.ViewSelectedTrade.changedInputs = setChangedInputs;
                          }
                        }
  
                    //set timeline datas
                    let actionsArray = [];
                    if (
                      res.data.trade.SavedBy != null &&
                      res.data.trade.SavedBy != ""
                    ) {
                      actionsArray.push({
                        type: "Saved",
                        user: res.data.trade.SavedBy ?? "",
                        date:
                          res.data.trade.SaveDate.split(" ")[0] +
                          " " +
                          res.data.trade.SaveTime,
                        reason: "-",
                      });
                    }
  
                    if (
                      res.data.trade.AmendedBy != null &&
                      res.data.trade.AmendedBy != ""
                    ) {
                      actionsArray.push({
                        type: "Amended",
                        user: res.data.trade.AmendedBy ?? "",
                        date:
                          res.data.trade.AmendmentDate.split(" ")[0] +
                          " " +
                          res.data.trade.AmendmentTime,
                        reason: res.data.trade.AmendmentReason,
                      });
                    }
                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].VerificationUser != null &&
                        res.data.actions[0].VerificationUser != ""
                      ) {
                        this.$refs.ViewSelectedTrade.checkVerify = true;
                        actionsArray.push({
                          type: "Verified",
                          user: res.data.actions[0].VerificationUser ?? "",
                          date: res.data.actions[0].VerificationDateTime,
                          reason: "-",
                        });
                      }
                    }
  
                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].JournalUser != null &&
                        res.data.actions[0].JournalUser != ""
                      ) {
                        actionsArray.push({
                          type: "Journal Created",
                          user: res.data.actions[0].JournalUser ?? "",
                          date: res.data.actions[0].JournalDateTime,
                          reason: "-",
                        });
                      }
                    }
  
                    if (
                      res.data.trade.CancelledBy != null &&
                      res.data.trade.CancelledBy != ""
                    ) {
                      actionsArray.push({
                        type: "Canceled",
                        user: res.data.trade.CancelledBy ?? "",
                        date:
                          res.data.trade.CancelDate.split(" ")[0] +
                          " " +
                          res.data.trade.CancelTime,
                        reason: res.data.trade.CancelReason,
                      });
                    }
  
                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].ReversalInitiatedBy != null &&
                        res.data.actions[0].ReversalInitiatedBy != ""
                      ) {
                        actionsArray.push({
                          type: "Reversal Initiated",
                          user: res.data.actions[0].ReversalInitiatedBy ?? "",
                          date: res.data.actions[0].ReversalInitiatedDateTime,
                          reason: res.data.actions[0].ReversalInitiatedReason,
                        });
                      }
                    }
  
                    if (res.data.actions.length > 0) {
                      if (
                        res.data.actions[0].ReversedBy != null &&
                        res.data.actions[0].ReversedBy != ""
                      ) {
                        actionsArray.push({
                          type: "Reversed",
                          user: res.data.actions[0].ReversedBy ?? "",
                          date: res.data.actions[0].ReversedDateTime,
                          reason: "-",
                        });
                      }
                    }
  
                    this.$refs.ViewSelectedTrade.actionsData = actionsArray;
                  } else {
                    this.notFoundMessage();
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              this.tradeIDDoesntZeroMessage();
            }
          } else {
            this.notAuthToastMessage();
          }
        });
      },
  
      databaseErrorMessage(val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: this.$t('fx_options.toast_messages.database_connection_error'),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      },
      errorMessage(message) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      },
      notFoundMessage(val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Not Found",
            text: this.$t('fx_options.toast_messages.data_not_found'),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      },
      warningToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
      wrongToastMessage(val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error",
            text: val,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      },
      notAuthToastMessage(val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Not Authorized",
            text: this.$t('fx_options.general_messages.not_authorized_message'),
            icon: "UserXIcon",
            variant: "warning",
          },
        });
      },
  
      tradeIDDoesntZeroMessage(val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: this.$t('fx_options.messages.trade_id_cannot_zero'),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      dateFormat(val) {
        if (val != undefined) {
          return moment(String(val)).format("DD-MM-YYYY");
        }
      },
      dateFormatWithTime(val) {
        if (val != undefined) {
          return moment(String(val)).format("MM-DD-YYYY HH:mm:ss");
        }
      },
      DMYdateFormat(val) {
        if (val != undefined) {
          return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
        }
      },
      timeFormat(val) {
        if (val != undefined) {
          return moment(String(val)).format("HH:mm:ss");
        }
      },
      formatPrice(value, val) {
        if (value != null) {
          let val = (value / 1).toFixed(2).replace(",", ".");
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      },
      YMDdateFormat(val) {
        if (val != undefined) {
          return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
        }
      },
      clearTradeDate() {
        this.filterTradeDate = null;
        this.runLastSearch();
      },
      clearSettlementDate() {
        this.filterSettlementDate = null;
        this.runLastSearch();
      },
      handleScroll(e) {
        // this.windowTop = window.top.scrollY
        // if(this.windowTop > 300) {
        //   console.log(this.$refs.refFxExecuteTable.$el)
        // }
      },
    },
    watch: {
      perPage: {
        handler: function(val, before) {
          localStorage.setItem("bringSameOptionsShow", val);
        },
      },
    },
  };
  </script>
  
  <style>
  
  .table-executed {
    background-color: #e9fafa !important;
  }
  .table-verified {
    background-color: #00ff7f !important;
  }
  .table-journalCreate {
    background-color: #e1d7f5 !important;
  }
  .table-expiredOrder {
    background-color: silver !important;
  }
  .table-reverseInitiated {
    background-color: #ffff99 !important;
  }
  
  .table-reversed {
    background-color: coral!important;
  }
  
  .table-canceled {
    background-color: #ffb8b8 !important;
  }
  .table-current {
    background-color: #fcfcab !important;
  }
  
  .ID_background {
    background-color: gainsboro !important;
  }
  
    /* width */
    ::-webkit-scrollbar {
      width: 5px;
      height:5px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey; 
      border-radius: 10px;
    }
     
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: grey;
      border-radius: 10px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgb(77, 73, 73); 
    }
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  </style>
  
  
  <!-- compact design scss code -->
    
  <style lang="scss" scoped>
    
    @import "~/src/assets/scss/compact-design.scss";
  
  
  .card::v-deep .card-body {
      padding: 0.8rem 0.7rem !important;
    
      .card-title {
          font-weight: 500 !important;
          font-size: 1.285rem !important;
          margin-bottom: 0.6rem !important;
          text-decoration: none !important;
      }
    }
  
  
  
  </style>
  